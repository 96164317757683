
import React, {Component} from 'react';
import { Waypoint } from 'react-waypoint';
import '../css/desktop/ChiSiamoSection.css'
import '../css/mobile/ChiSiamoSection.css'
import '../css/tablet/ChiSiamoSection.css'

import image1 from '../../images/chiSiamo/chiSiamo1.png';
import image2 from '../../images/chiSiamo/chiSiamo2.png';
import image3 from '../../images/chiSiamo/chiSiamo3.png';
import image4 from '../../images/chiSiamo/chiSiamo4.png';
import cup from '../../images/chiSiamo/cup.png';
import mission from '../../images/chiSiamo/mission.png';

class ChiSiamoSection extends Component{

    state={
        animations: ["", "", "", "" ],
        animationSpeed: 20,
        animationDuration: 500,
        items:[
            {id: 0, image: image1, text: ""},
            {id: 1, image: image1, text: ""},
            {id: 2, image: image1, text: ""},
            {id: 3, image: image1, text: ""},
            {id: 4, image: image1, text: ""}
        ]
    }

    getImage(id){
        switch(id){
            case 0: return image1;
            case 1: return image2;
            case 2: return image3;
            case 3: return image4;
            case 4: return mission;
            default: console.error("image not found"); return "";
        }
    }
    componentDidMount(){
        let items = [];
        let items_conf = window.CHISIAMO_CONFIG;
        for(let i = 0; i < items_conf.length; i++){
            let id = i;
            let image = this.getImage(i);
            let text = items_conf[i].text;
            let item = {id: id, image: image, text: text};
            items.push(item);
        }
        this.setState({items});
    }

    startAnimation(id){
        if(document.getElementsByClassName("cs-obscurator")[id].display == "none")
            return;
        let obscurator = document.getElementsByClassName("cs-obscurator")[id];
        obscurator.style.width = '100%';
        this.state.animations[id] = setInterval(
            () => {
                let value = Number((obscurator.style.width).replace("%",""));
                let animationStep = Math.floor(100/ (this.state.animationDuration / this.state.animationSpeed))
                if( value > 0){
                    value -= animationStep;
                    obscurator.style.width = value + "%";
                }
                else{
                    obscurator.style.display = "none";
                    clearInterval(this.state.animations[id])
                }
            }
        , this.state.animationSpeed);
    }

    render(){
        return(
            <div id="cs-container">
                <label id="cs-title">Chi Siamo</label>
                <p id="cs-separator"></p>
                <div className="cs-cont" id="cs-cont-1">
                    <div className="cs-obscurator" style={{right: "0"}}></div>
                    <div className="cs-cont-sx">
                        <p className="cs-text">
                            {this.state.items[0].text}
                        </p>
                        <img alt="..." className="cs-image" src={this.state.items[0].image}/>
                    </div>
                </div>
                <Waypoint onEnter={() => this.startAnimation(0)} />
                <div  className="cs-cont" id="cs-cont-2">
                    <div className="cs-obscurator" style={{left: "0"}}></div>
                    <div className="cs-cont-dx">
                        <p className="cs-text">
                            {this.state.items[1].text}
                        </p>
                        <img alt="..." className="cs-image" src={this.state.items[1].image}/>
                    </div>
                </div>
                <Waypoint onEnter={() => this.startAnimation(1)} /> 
                <div  className="cs-cont" id="cs-cont-3">
                    <div className="cs-obscurator" style={{right: "0"}}></div>
                    <div className="cs-cont-sx">
                        <p className="cs-text">
                            {this.state.items[2].text}
                        </p>
                        <img alt="..." className="cs-image" src={this.state.items[2].image}/>
                    </div>
                </div>
                <Waypoint onEnter={() => this.startAnimation(2)} /> 
                <div  className="cs-cont" id="cs-cont-4">
                    <div className="cs-obscurator" style={{left: "0"}}></div>
                    <div className="cs-cont-dx">
                        <p className="cs-text">
                            {this.state.items[3].text}
                        </p>
                        <img alt="..." className="cs-image" src={this.state.items[3].image}/>
                    </div>
                </div>
                <Waypoint onEnter={() => this.startAnimation(3)} /> 
                <div id="cs-mission">
                    <div id="cs-mission-1">
                        <label id="cs-mission-title">LA NOSTRA MISSION</label>
                        <img alt="..." id="cs-mission-cup" src={cup} />
                    </div>
                    <p id="cs-mission-text">
                        {this.state.items[4].text}
                    </p>
                    <img alt="..." id="cs-mission-image" src={this.state.items[4].image} />
                </div>
            </div>
        )
    }
}

export default ChiSiamoSection;

