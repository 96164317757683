
import React, {Component} from 'react'
import Iframe from 'react-iframe'
import '../css/desktop/ContattiSection.css'
import '../css/mobile/ContattiSection.css'
import '../css/tablet/ContattiSection.css'

import phone from '../../images/contatti/phone.png'
import mail from '../../images/contatti/mail.png'
import facebook from '../../images/contatti/facebook.png'
import linkedin from '../../images/contatti/linkedin.png'
import instagram from '../../images/contatti/instagram.png'
          
class ContattiSection extends Component{

    state={
        mail: [],
        tel: [],
        social: [],
        policyLink: "",
        cookiePolicyLink: ""
    }

    getImage(image){
        if(image == "facebook")
            return facebook;
        else if(image == "instagram")
            return instagram;
        else if(image == "linkedin")
            return linkedin;
        else{
            console.error("image not found");
            return image;
        }
    }

    componentDidMount(){
        let policyLink = window.CONTATS_CONFIG.policyLink;
        let cookiePolicyLink = window.CONTATS_CONFIG.cookiePolicyLink;
        let mail = window.CONTATS_CONFIG.mail;
        let tel = window.CONTATS_CONFIG.tel;
        let social_config = window.CONTATS_CONFIG.social;
        let social = [];
        for(let i = 0; i < social_config.length; i++){
            let id = i;
            let link = social_config[i].link;
            let image = this.getImage(social_config[i].image);
            let item = { id: id, image: image, link: link};
            social.push(item);
        }
        this.setState({mail, social, tel, policyLink, cookiePolicyLink});
    }

    render(){
        return(
            <div id="conts-container">
                <label id="conts-title">Contatti</label>
                <p id="conts-separator"></p>
                <div id="conts-cont-1">
                    <Iframe id="conts-map" url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2947.0649125482487!2d12.265811615637533!3d42.38376364159677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f2568c94eb287%3A0x67555d1b75ab6d5a!2sVia%20Don%20Minzoni%2C%2037%2C%2001030%20Vallerano%20VT!5e0!3m2!1sit!2sit!4v1668009884409!5m2!1sit!2sit" width="100%" height="100%" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </div>
                <div id="conts-cont-2">
                    <div className="conts-items">
                        <div className="conts-items-header">
                            <img alt="..." className="conts-items-image" src={mail}/>
                            <label className="conts-items-title">Contattaci Per Mail</label>
                        </div>
                        <div className="conts-items-list">
                            {
                                this.state.mail.map(mail => (
                                    <div
                                        key={mail.id}
                                        className="conts-item-cont"
                                    >
                                        <label
                                            className="conts-item-name"
                                            >
                                            {mail.name}
                                        </label>
                                        <a 
                                            key={mail.id} 
                                            className="conts-item-mail" 
                                            href={mail.link}
                                            target='_blank' 
                                            rel="noopener noreferrer">
                                                {mail.text}
                                        </a>
                                    </div>
                                ))
                            }
                        </div>  
                    </div>
                    <div className="conts-items">
                        <div className="conts-items-header">
                            <img alt="..." className="conts-items-image" src={phone}/>
                            <label className="conts-items-title">Contattaci Telefonicamente</label>
                        </div>
                        <div className="conts-items-list">
                            {
                                this.state.tel.map(tel => (
                                    <div
                                        key={tel.id}
                                        className="conts-item-cont"
                                    >
                                        <label
                                            className="conts-item-name"
                                            >
                                            {tel.name}
                                        </label>
                                        <a 
                                            className="conts-item-tel" 
                                            href={tel.link}
                                            target='_blank' 
                                            rel="noopener noreferrer">
                                                {tel.text}
                                        </a>
                                    </div>
                                ))
                            }
                        </div>  
                    </div>
                    <div className="conts-items">
                        <div className="conts-items-header">
                            <label className="conts-items-title">I Nostri Social</label>
                        </div>
                        <div className="conts-social">
                            {
                                this.state.social.map(social => (
                                    <a 
                                        key={social.id} 
                                        href={social.link}
                                        target='_blank' 
                                        rel="noopener noreferrer">
                                        <img 
                                            alt="..." 
                                            className="conts-item-social" 
                                            src={social.image} 
                                        />
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div id="conts-footer">
                    <p id="conts-footer-separator"></p>
                    <div id="conts-footer-items">
                        <label id="conts-footer-firm">Gruppo Multiservizi 360</label>
                        <div id="conts-footer-policy-cont">
                            <a className="conts-footer-policy" href={this.state.policyLink} style={{marginRight: "0.5rem"}} target='_blank' rel="noopener noreferrer">Privacy Policy</a>
                            <a className="conts-footer-policy" href={this.state.cookiePolicyLink} target='_blank' rel="noopener noreferrer">Cookie Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContattiSection;

