
import React, {Component} from 'react';
import '../css/desktop/ContattaciSection.css'
import '../css/mobile/ContattaciSection.css'
import '../css/tablet/ContattaciSection.css'

import image from '../../images/contatti/contattaci.png'
import letter from '../../images/contatti/letter.png'

function boundedTranslationX(value){
    if(value > 100)
        value = 100;
    return value + "%";
}

function boundedTranslationY(value){
    if(value < 0)
        value = 0;
    return value + "%";
}

function boundedScale(value){
    let float = parseFloat(value);
    if(float > 1)
        float = 1;
    if(float < 0)
        float = 0;
    return (float + "");
}

class ContattaciSection extends Component{

    state={
        animated: false,
        direction: false,
        interval: "",
        animationSpeed: 40,
        animationDuration: 1000,
        top: "50%",
        left: "50%",
        scale: 1,
        buttonDisplay: "block",
        letterDisplay: "none",
        privacyText: "",
        policyLink: ""
    }

    componentDidMount(){
        let privacyText = window.PRIVACY_CONFIG;
        let policyLink = window.CONTATS_CONFIG.policyLink;
        this.setState({privacyText, policyLink});
    }

    onSend(){
        
        // get data from compiled form
        let name = document.getElementById("cont-input-name").value;
        let tel = document.getElementById("cont-input-tel").value;
        let email = document.getElementById("cont-input-email").value;
        let prod = document.getElementById("cont-prodotto").value;
        let pref = "";
        if(document.getElementsByName("cont-input-radio")[0].checked)
            pref = document.getElementsByName("cont-input-radio")[0].value;
        else if(document.getElementsByName("cont-input-radio")[1].checked)
            pref = document.getElementsByName("cont-input-radio")[1].value;
        let request = document.getElementById("cont-input-request").value;
        let aboutData = "";
        if(document.getElementsByName("cont-policy-radio")[0].checked)
            aboutData = document.getElementsByName("cont-policy-radio")[0].value;
        else if(document.getElementsByName("cont-policy-radio")[1].checked)
            aboutData = document.getElementsByName("cont-policy-radio")[1].value;
        let policy = document.getElementById("cont-cont-policy-checkbox").checked ? "true" : "false";

        //validate data
        let error = ""; 
        if( name == null || name.trim() == "")
            error = "Il campo 'Nome e Cognome' non è valido";
        if( tel == null || tel.trim() == "" || isNaN(Number(tel)))
            error = "Il campo 'Telefono' non è valido";
        if( email == null || email.trim() == "" || email.search('@') == -1)
            error = "Il campo 'Email' non è valido";
        if( pref == null || pref.trim() == "")
            error = "Selezionare una preferenza di contatto";
        if( pref == null || aboutData.trim() == "" || aboutData == document.getElementsByName("cont-policy-radio")[1].value)
            error = "Acconsentire al trattamento dei dati per inviare il modulo";
        if( policy == "false")
            error = "Accettare la privacy policy";
    
        let json = {};
        if(error != ""){
            json['type'] = "error";
            json['msg'] = error;
        }            
        else{
            json['type'] = "new_request";
            json['msg'] = "new_request";
            json['name'] = name;
            json['tel'] = tel;
            json['email'] = email;
            json['prod'] = prod;
            json['pref'] = pref;
            json['req'] = request;
        }
        
        if(error == "")
            this.startAnimation();
        this.props.sendContactRequest(json);
    }

    startAnimation(){
        if(this.state.animated)
            return;
        let interval = setInterval(
            () => {
                let top = this.state.top;
                let left = this.state.left;
                let scale = this.state.scale;
                let direction = this.state.direction;
                let letterDisplay = this.state.letterDisplay;
                let translationStepX1 = Math.floor(50 / ((this.state.animationDuration/2) / this.state.animationSpeed));
                let translationStepX2 = Math.floor(100 / ((this.state.animationDuration/2) / this.state.animationSpeed));
                let translationStepY = Math.floor(50 / (this.state.animationDuration / this.state.animationSpeed));
                let scaleStep = parseFloat(1 / (this.state.animationDuration / this.state.animationSpeed));

                top = boundedTranslationY(Number(top.replace("%", "")) - translationStepY);
                if(direction)
                    left = boundedTranslationX(Number(left.replace("%", "")) + translationStepX2);
                else
                    left = boundedTranslationY(Number(left.replace("%", "")) - translationStepX1);
                scale = boundedScale(Number(scale) - scaleStep);
        
                if(left == "0%"){
                    direction = !direction;
                    letterDisplay = "block";
                }
                if(left == "100%"){
                    clearInterval(this.state.interval);
                    top = "50%";
                    left = "50%";
                    scale = "1";
                    this.setState({animated: false, top, left, scale, direction: false, buttonDisplay: "block", letterDisplay: "none"},
                        () => {
                            document.getElementById("cont-input-name").value = "";
                            document.getElementById("cont-input-tel").value = "";
                            document.getElementById("cont-input-email").value = "";
                            document.getElementById("cont-input-request").value = "";
                            document.getElementById("cont-prodotto").value = "Luce e Gas";
                            document.getElementsByName("cont-input-radio")[0].checked = false;
                            document.getElementsByName("cont-input-radio")[1].checked = false;
                            document.getElementsByName("cont-policy-radio")[0].checked = false;
                            document.getElementsByName("cont-policy-radio")[1].checked = false;
                            document.getElementById("cont-cont-policy-checkbox").checked = false;

                        }
                    );
                    return;
                }
                this.setState({top, left, scale, direction, letterDisplay});

            }, this.state.animationSpeed);
        this.setState({interval, animated: true, buttonDisplay: "none"});
    }

    render(){
        return(
            <div id="cont-container">
                <div id="cont-cont-0" style={{left: this.state.left, top: this.state.top, scale: this.state.scale}}>
                    <div id="cont-cont-0-1">
                        <img id="cont-letter" src={letter} style={{display: this.state.letterDisplay}}/>
                        <img id="cont-image" src={image} style={{display: this.state.buttonDisplay}}/>
                        <label id="cont-title">CHIEDI A NOI</label>
                        <div id="cont-cont-1">
                            <div className="cont-input">
                                <label className="cont-label">Nome e Cognome<label style={{color:"red"}}>*</label></label>
                                <input id="cont-input-name" className="cont-input-text" type="text" />
                            </div>
                            <div className="cont-input">
                                <label className="cont-label">Telefono<label style={{color:"red"}}>*</label></label>
                                <input id="cont-input-tel" className="cont-input-text" type="text" />
                            </div>
                            <div className="cont-input">
                                <label className="cont-label">Email<label style={{color:"red"}}>*</label></label>
                                <input id="cont-input-email" className="cont-input-text" type="text" />
                            </div>
                        </div>
                        <div id="cont-cont-2">
                            <div id="cont-cont-2-1">
                                <div className="cont-input">
                                    <label className="cont-label">Prodotto:</label>
                                    <select id="cont-prodotto" className="cont-input-select" >
                                        <option value="Luce e Gas">Luce e Gas</option>
                                        <option value="Fotovoltaico">Fotovoltaico</option>
                                        <option value="Mobile e Internet">Mobile e Internet</option>
                                        <option value="Conciliazioni">Conciliazioni</option>
                                        <option value="Altro">Altro</option>
                                        <option value="Lavora Con Noi">Lavora Con Noi</option>
                                    </select>
                                </div>
                                <div className="cont-radio">
                                    <label className="cont-label">Preferenza Contatto<label style={{color:"red"}}>*</label></label>
                                    <div className="cont-cont-radio">
                                        <input className="cont-input-radio" type="radio" name="cont-input-radio" value="Telefono" />
                                        <label className="cont-label">Telefono</label>
                                    </div>
                                    <div className="cont-cont-radio">
                                        <input className="cont-input-radio" type="radio" name="cont-input-radio" value="Email" />
                                        <label className="cont-label">Indirizzo Email</label>
                                    </div>
                                </div>
                            </div>
                            <div className="cont-input">
                                <label className="cont-label">Scrivi qui la tua richiesta senza impegno</label>
                                <textarea id="cont-input-request" className="cont-input-text" type="text" />
                            </div>
                        </div>
                        <div id="cont-cont-3">
                            <div className="cont-cont-policy">
                                <div className="cont-cont-policy-radio">
                                    <input className="cont-cont-policy-input-radio" type="radio" name="cont-policy-radio" value="Acconsento" />
                                    <label className="cont-cont-policy-label">Acconsento</label>
                                    <input className="cont-cont-policy-input-radio" type="radio" name="cont-policy-radio" value="Non Acconsento" />
                                    <label className="cont-cont-policy-label">Non Acconsento</label>
                                </div>
                                <p className="cont-cont-policy-text">
                                    {this.state.privacyText}
                                </p>
                            </div>
                            <div id="cont-cont-policy-confirm">
                                <input type="checkbox" id="cont-cont-policy-checkbox" />
                                <label className="cont-cont-policy-label">
                                    Selezionando questa voce accetti le condizioni esposte nella <a id="cont-cont-policy-link" href={this.state.policyLink} target='_blank' rel="noopener noreferrer">privacy policy<label style={{color:"red"}}>*</label></a>
                                </label>
                            </div>
                        </div>
                    </div>
                    <label className="cont-button" onClick={() => this.onSend()} style={{display: this.state.buttonDisplay}}>INVIA</label>
                </div>
            </div>
        )
    }
}

export default ContattaciSection;

