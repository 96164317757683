import React, {Component} from 'react';
import '../css/desktop/LoginSection.css'
import '../css/mobile/LoginSection.css'
import '../css/tablet/LoginSection.css'

import info from '../../images/infoAlert.png';
import cross from '../../images/cross.png';

class LoginSection extends Component{

    state={
        username: "",
        password: "",
        msg: ""
    }

    handleAccedi(){
        let msg = "Autenticazione fallita";
        this.setState({msg});
    }

    handleCross(){
        let msg = "";
        document.getElementById("login-username-input").value = "";
        document.getElementById("login-password-input").value = "";
        this.setState({msg}, 
            this.props.onCross()
        );
    }


    render(){
        return(
            <>
                <div id="login-container" style={{display: this.props.display}}>
                    <div id="login-cont-0">
                        <div id="login-cont-1">
                            <div id="login-cont-1-1">
                                <img alt="..." src={cross} id="login-cross-image" onClick={() => this.handleCross()}/>
                            </div>
                            <div id="login-cont-1-2">
                                <img alt="..." src={info} id="login-info-image" />
                                <div id="login-auth">
                                    <div className="login-auth-cont">
                                        <label className="login-auth-label">Username</label>
                                        <input type="text" className="login-auth-input" id="login-username-input"></input>
                                    </div>
                                    <div className="login-auth-cont">
                                        <label className="login-auth-label">Password</label>
                                        <input type="password" className="login-auth-input" id="login-password-input"></input>
                                    </div>
                                    <button id="login-button" onClick={() => this.handleAccedi()}>ACCEDI</button>
                                    <label id="login-message">{this.state.msg}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default LoginSection;