
import React, {Component} from 'react'
import '../css/desktop/Carousel.css'
import '../css/mobile/Carousel.css'
import '../css/tablet/Carousel.css'

import arrowDx from '../../images/carousel/arrowDx.png'
import arrowSx from '../../images/carousel/arrowSx.png'
import image1 from '../../images/carousel/foto1.png'
import image2 from '../../images/carousel/foto2.png'
import image3 from '../../images/carousel/foto3.png'
import image4 from '../../images/carousel/foto4.png'
import image5 from '../../images/carousel/foto5.png'
       
class Carousel extends Component{

    state = {
        mounted: false,
        nextImage: 0,
        actualImage: 0,
        period: 5000,
        animation: false,
        animationSpeed: 40, //25fps
        animationStep: 0.04,
        items: []
    }

    getImages(id){
        switch(id){
            case 0 : return image1;
            case 1 : return image2;
            case 2 : return image3;
            case 3 : return image4;
            case 4 : return image5; 
            default: console.error("carousel image not found"); return "";
        }
    }

    componentDidMount(){

        let items = [];
        let items_conf = window.CAROUSEL_CONFIG;
        for(let i = 0; i < items_conf.length; i++){
            let id = i;
            let image = this.getImages(id);
            let slogan = items_conf[i].text;
            let item = {id: i, image: image, slogan: slogan};
            items.push(item);
        }
        
        this.setState({mounter: true, items},
            () => {
                document.getElementsByClassName("car-cont1-1-1")[0].style.opacity = 1;
                document.getElementsByClassName("car-image-selector")[0].style.backgroundColor = "white";
                setInterval(() => this.handleArrow(true), this.state.period);
                setInterval(() => this.animateImage(), this.state.animationSpeed);
            });
    }

    animateImage(){
        
        if(!this.state.animation)
            return;
        let oldImage = document.getElementsByClassName("car-cont1-1-1")[this.state.actualImage];
        let nextImage = document.getElementsByClassName("car-cont1-1-1")[this.state.nextImage];
        if(oldImage.style.opacity > 0){
            oldImage.style.opacity = Number(oldImage.style.opacity) - this.state.animationStep;
            nextImage.style.opacity = Number(nextImage.style.opacity) + this.state.animationStep;
        }
        else{
            oldImage.style.opacity = 0;
            nextImage.style.opacity = 1;
            this.setState({animation: false, actualImage: this.state.nextImage});
        }
    }

    handleArrow = (dir) => {
        if(this.state.animation)
            return;
        let n_items = this.state.items.length;
        let nextImage = this.state.actualImage;
        if(dir){
            nextImage++;
            if(nextImage >= n_items)
            nextImage = 0;
        }
        else{
            nextImage--;
            if(nextImage < 0)
                nextImage = n_items - 1;
        }
        for(let i = 0; i < this.state.items.length; i++){
            if(i == this.state.actualImage){
                document.getElementsByClassName("car-cont1-1-1")[i].style.zIndex = 1;
                document.getElementsByClassName("car-cont1-1-1")[i].style.opacity = 1;
            }
            else{
                document.getElementsByClassName("car-cont1-1-1")[i].style.zIndex = 0;
                document.getElementsByClassName("car-cont1-1-1")[i].style.opacity = 0;
            }
        }
        this.setState({animation: true, nextImage});
    }

    handleSelector = (id) => {
        if(this.state.animation)
            return;
        if(id == this.state.actualImage)
            return;
        let nextImage = id;
        for(let i = 0; i < this.state.items.length; i++){
            if(i == this.state.actualImage){
                document.getElementsByClassName("car-cont1-1-1")[i].style.zIndex = 1;
                document.getElementsByClassName("car-cont1-1-1")[i].style.opacity = 1;
            }
            else{
                document.getElementsByClassName("car-cont1-1-1")[i].style.zIndex = 0;
                document.getElementsByClassName("car-cont1-1-1")[i].style.opacity = 0;
            }
        }
        this.setState({animation: true, nextImage});
    }

    render(){

        let styleSelector = [];
        for(let i = 0; i < this.state.items.length; i++){
            if( i == this.state.actualImage)
                styleSelector.push({backgroundColor: "white"});
            else
                styleSelector.push({backgroundColor: "transparent"});

        }

        return(
            <div id="carousel-container">
                <img alt="..." className="car-arrow" src={arrowSx} onClick={() => this.handleArrow(false)}></img>
                <div id="car-cont1">
                    <div id="car-cont1-1">
                        {
                            this.state.items.map(item => (
                                <div className='car-cont1-1-1' key={item.id}>
                                    <img
                                        alt="..."
                                        className="car-image"
                                        src={item.image}
                                    />
                                    <label className="car-slogan">
                                        {item.slogan}
                                    </label>
                                </div>
                            ))
                        }
                        
                    </div>
                    <div id="car-cont1-2">
                        {
                            this.state.items.map(item => (
                                <div
                                    key={item.id}
                                    className="car-image-selector"
                                    onClick={() => this.handleSelector(item.id)}
                                    style = {styleSelector[item.id]}
                                ></div>
                            ))
                        }
                    </div>
                </div>
                <img alt="..." className="car-arrow" src={arrowDx} onClick={() => this.handleArrow(true)}></img>
            </div>
        )
    }
}

export default Carousel;