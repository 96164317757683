import React, {Component} from 'react';
import '../css/desktop/ServiceInfo.css'
import '../css/mobile/ServiceInfo.css'
import '../css/tablet/ServiceInfo.css'

import cross from '../../images/services/cross.png'

class ServiceInfo extends Component{

    render(){
        return(
            <div id="serInf-container" style={{
                    display: this.props.display,
                    scale: this.props.scale, 
                    top: this.props.top, 
                    left: this.props.left, 
                    right: this.props.right,
                    bottom: this.props.bottom,
                    transform: "translate(" + 
                        (this.props.left == "auto" ? "+" : "-") + 
                        (this.props.left == "auto" ? this.props.right : this.props.left) + 
                        ", " +
                        (this.props.top == "auto" ? "+" : "-") +
                        (this.props.top == "auto" ? this.props.bottom : this.props.top) + 
                        ")" 
                }}>
                <img alt="..." id="serInf-cross" src={cross} onClick={() => this.props.handleCross()}/>
                <label id="serInf-title">{this.props.service.title}</label>
                <img alt="..." id="serInf-image" src={this.props.service.image} />
                <p id="serInf-text">{this.props.service.bigText}</p>
            </div>
        )
    }
}

export default ServiceInfo;