
import React, {Component} from 'react';
import '../css/desktop/Blocker.css'
import '../css/mobile/Blocker.css'
import '../css/tablet/Blocker.css'

class Blocker extends Component{

    render(){
        return(
            <div id="blocker-container" style={{display: this.props.display}}>
            </div>
        )
    }
}

export default Blocker;