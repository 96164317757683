
import React, {Component} from 'react';
import '../css/desktop/Alert.css'
import '../css/mobile/Alert.css'
import '../css/tablet/Alert.css'

import info from '../../images/infoAlert.png';
import cross from '../../images/cross.png';

class Alert extends Component{

    render(){
        return(
            <>
                <div id="alert-container" style={{display: this.props.display}}>
                    <div id="alert-cont-0">
                        <div id="alert-cont-1">
                            <div id="alert-cont-1-1">
                                <img alt="..." src={cross} id="alert-cross-image" onClick={() => this.props.onCross()}/>
                            </div>
                            <div id="alert-cont-1-2">
                                <img alt="..." src={info} id="alert-info-image" />
                                <p id="alert-text">{this.props.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Alert;