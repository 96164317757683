
import React, {Component} from 'react';
import './App.css'

import Alert from './components/js/Alert';
import Blocker from './components/js/Blocker';
import Navbar from './components/js/Navbar';
import Carousel from './components/js/Carousel';
import ChiSiamoSection from './components/js/ChiSiamoSection';
import StatSection from './components/js/StatSection';
import ServicesSection from './components/js/ServicesSection';
import ContattaciSection from './components/js/ContattaciSection';
import ContattiSection from './components/js/ContattiSection';
import LoginSection from './components/js/LoginSection';

class App extends Component{

  state = {
    serverIp: "",
    serverPort: "",
    page: 0,
    alertDisplay: "none",
    alertText: "",
    loginDisplay: "none"
  }

  handleJob = () => {
    console.log(document.getElementById("cont-prodotto").value);
    document.getElementById("cont-prodotto").value = "Lavora Con Noi";
    console.log(document.getElementById("cont-prodotto").value);
  }

  handleARSection = () => {
    this.openLogin();
  }

  openAlert(text){
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    this.setState({alertDisplay: "flex", alertText: text});
  }

  handleCloseAlert = () => {
    if(document.getElementById("nav-menu").style.display == "none")
      document.getElementsByTagName("body")[0].style.overflowY = "auto";
    this.setState({alertDisplay: "none"});
  }

  openLogin(){
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    this.setState({loginDisplay: "flex"});
  }

  handleCloseLogin = () => {
    if(document.getElementById("nav-menu").style.display == "none")
      document.getElementsByTagName("body")[0].style.overflowY = "auto";
    this.setState({loginDisplay: "none"});
  }


  componentDidMount(){
    let serverIp = window.SERVER_IP;
    let serverPort = window.SERVER_PORT;
    this.setState({serverIp, serverPort});
  }

  sendContactRequest = (json) => {

    if(json['type'] == "error"){
      this.openAlert(json['msg']);
      return;
    }

    let url = this.state.serverIp + this.state.serverPort + "/backend/mail.php";
    let msg = "body=" + JSON.stringify(json);
   
    fetch(url, {
        method : "POST",
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
        body : msg
    }).then(
        response => response.json()
    ).then(
        html => {
            if (html.status == "ERROR") {
              console.error(html.msg);
              this.openAlert("Invio fallito, ci scusiamo per il disagio");
            }
            else if(html.status == "SUCCESS")
              this.openAlert("Richiesta di contatto inviata con successo, ti contatteremo il prima possibile");
            else
              this.openAlert("Errore sconosciuto, ci scusiamo per il disagio")
        }
    ).catch( err => {
        console.error(err);
        this.openAlert("Il servizio non è al momento disponibile, ci scusiamo per il disagio");

      }
    );

  }

  render(){
    let page;

    if(this.state.page == 0){
      page = <> 
                <Alert 
                  display={this.state.alertDisplay}
                  text={this.state.alertText}
                  onCross={this.handleCloseAlert}
                />
                <LoginSection
                  display={this.state.loginDisplay}
                  onCross={this.handleCloseLogin}
                />
                <Blocker
                  display={this.state.alertDisplay}
                 />
                 <Blocker
                  display={this.state.loginDisplay}
                 />
                <Navbar 
                  onJob = {this.handleJob}
                  onARSection = {this.handleARSection}
                  greenNumber = {window.NAVBAR_CONFIG.greenNumber}
                  days = {window.NAVBAR_CONFIG.days}
                  hours = {window.NAVBAR_CONFIG.hours}
                  icons = {window.NAVBAR_CONFIG.icons}
                />
                <Carousel />
                <ChiSiamoSection />
                <StatSection />
                <ServicesSection />
                <ContattaciSection 
                  sendContactRequest = {this.sendContactRequest}
                />
                <ContattiSection />
             </>
    }
    else{
      page = <>
                
            </>
    }
    return (
      <>
        {page}
      </>
    );
  }
}

export default App;

