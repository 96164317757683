
import React, {Component} from 'react';
import { Waypoint } from 'react-waypoint';
import {Link} from 'react-scroll'
import '../css/desktop/Navbar.css'
import '../css/mobile/Navbar.css'
import '../css/tablet/Navbar.css'

import logo from '../../images/navbar/logo.png';
import facebook from '../../images/navbar/facebook.png'
import linkedin from '../../images/navbar/linkedin.png'
import mail from '../../images/navbar/mail.png'
import instagram from '../../images/navbar/instagram.png'
import job from '../../images/navbar/job.png'
import menu from '../../images/navbar/menu.png'
import greenNumber from '../../images/navbar/greenNumber.png'

function convertRemToPixels(rem) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

class Navbar extends Component{

    state={
        greenNumber: "",
        days: "",
        hours: "",
        icons:[
            {id: 0, image: facebook, link: "https://it-it.facebook.com/"},
            {id: 1, image: linkedin, link: "https://it.linkedin.com/"},
            {id: 2, image: mail, link: "mailto:amministrazione@gruppomultiservizi360.it"},
        ],
        reduced: "none",
        displayMenu: "none",
        animated: false,
        animationSpeed: 40,
        animationDuration: 200,
        animationInterval: "",
        rightMenu: "-20rem"
    }

    componentDidMount(){
        let greenNumber = this.props.greenNumber;
        let days = this.props.days;
        let hours = this.props.hours; 
        let icons_conf =  this.props.icons;
        let icons = [];
        for(let i = 0; i < icons_conf.length; i++){
            let id = i;
            let image = "";
            let link = icons_conf[i].link;
            if(icons_conf[i].name == "facebook")
                image = facebook;
            else if(icons_conf[i].name == "linkedin")
                image = linkedin;
            else if(icons_conf[i].name == "mail")
                image = mail;
            else if(icons_conf[i].name == "instagram")
                image = instagram;
            icons.push({id: id, image: image, link: link});
        }
        this.setState({greenNumber, days, hours, icons});
    }

    enterInNavbas(){
        this.setState({reduced: "flex"});
    }

    leaveNavbar(){
        this.setState({reduced: "none"});
    }

    onButton(){
        if(this.state.animated)
            return;

        if(this.state.displayMenu != "none"){
            this.onOutMenu();
            return;
        }

        document.getElementsByTagName("body")[0].style.overflowY = "hidden";
        let interval = setInterval(
            () =>{
                let right = Number(this.state.rightMenu.replace("rem","").replace("-",""));
                let step = 20 / (this.state.animationDuration / this.state.animationSpeed);
                right = right - step;
                if(right <= 1){
                    right = "-1rem";
                    clearInterval(this.state.animationInterval);
                    this.setState({rightMenu: right, animated: false});
                    return;
                }

                right = "-" + right + "rem";

                this.setState({rightMenu: right});

            }
        ,this.state.animationSpeed);
        this.setState({displayMenu: "flex", animationInterval: interval, animated: true, rightMenu: "-20rem"});
    }

    onOutMenu(){
        if(this.animated)
            return;
            
        document.getElementsByTagName("body")[0].style.overflowY = "auto";

        let interval = setInterval(
            () =>{
                let right = Number(this.state.rightMenu.replace("rem","").replace("-",""));
                let step = 20 / (this.state.animationDuration / this.state.animationSpeed);
                right = right + step;
                if(right >= 20){
                    right = "-20rem";
                    clearInterval(this.state.animationInterval);
                    this.setState({rightMenu: right, animated: false, displayMenu: "none"});
                    return;
                }

                right = "-" + right + "rem";

                this.setState({rightMenu: right});

            }
        ,this.state.animationSpeed);
        this.setState({animationInterval: interval, animated: true});
    
    }

    render(){
        return(
            <>
                <Waypoint onEnter={() => this.enterInNavbas()} onLeave={() => this.leaveNavbar()} />
                <div id="nav-container">
                    <div id="nav-cont1">
                        <Link to={"carousel-container"} offset={-convertRemToPixels(10)} smooth={true}><img alt="..." id="nav-logo" src={logo} /></Link>
                        <div id="nav-button">
                            <img alt="..." id="nav-button-image" src={menu} onClick={() => this.onButton()}/>
                        </div>
                    </div>
                    <div id="nav-cont2">
                        <div id="nav-cont2-1" style={{display: this.state.reduced}}>
                            <div id="nav-greenNumber-cont">
                                <img alt="..." src={greenNumber} id="nav-greenNumber-image" />
                                <label id="nav-greenNumber-text">{this.state.greenNumber}</label>
                            </div>
                            <div id="nav-receptions-cont">
                                <label className="nav-receptions-label">{this.state.days}</label>
                                <label className="nav-receptions-label">{this.state.hours}</label>
                            </div>
                            {
                                this.state.icons.map( icon => (
                                    <a key={icon.id} href={icon.link} className="nav-social-link" target='_blank' rel="noopener noreferrer">
                                        <img alt="..." className="nav-social-image" src={icon.image} />
                                    </a>
                                ))
                            }
                            <Link onClick={() => this.props.onJob()} offset={-convertRemToPixels(5)} smooth={true} to={"cont-container"} className="nav-social-link" style={{marginRight: '2rem'}}>
                                <img alt="..." className="nav-social-image" src={job} />
                            </Link>
                        </div>
                        <div id="nav-cont2-2">
                            <Link className="nav-label" offset={-convertRemToPixels(5)} to={"cs-container"} smooth={true}>Chi siamo</Link>
                            <Link className="nav-label" offset={-convertRemToPixels(5)} to={"ser-container"} smooth={true}>Servizi</Link>
                            <Link className="nav-label" offset={-convertRemToPixels(5)} to={"conts-container"} smooth={true}>Contatti</Link>
                            <label className="nav-label" onClick={() => this.props.onARSection()} style={{marginRight: '2rem'}}>Area Riservata</label>
                        </div>
                    </div>
                </div>
                <div id="nav-menu-container" style={{display: this.state.displayMenu}}>
                    <div id="nav-menu-listener" onClick={() => this.onOutMenu()}></div>
                    <div id="nav-menu" style={{display: this.state.displayMenu, right: this.state.rightMenu}}>
                        <div id="nav-menu-header">
                            <div id="nav-greenNumber-cont">
                                <img alt="..." src={greenNumber} id="nav-greenNumber-image" />
                                <label id="nav-greenNumber-text">{this.state.greenNumber}</label>
                            </div>
                            <div id="nav-receptions-cont">
                                <label className="nav-header-label" style={{marginRight: '0.5rem'}}>{this.state.days}</label>
                                <label className="nav-header-label">{this.state.hours}</label>
                            </div>
                        </div>
                        <div id="nav-menu-list">
                            <Link className="nav-menu-label" offset={-convertRemToPixels(5)} to={"cs-container"} smooth={true}>Chi siamo</Link>
                            <Link className="nav-menu-label" offset={-convertRemToPixels(5)} to={"ser-container"} smooth={true}>Servizi</Link>
                            <Link className="nav-menu-label" offset={-convertRemToPixels(5)} to={"conts-container"} smooth={true}>Contatti</Link>
                            <label className="nav-menu-label" onClick={() => this.props.onARSection()}>Area Riservata</label>
                        </div>
                        <p id="nav-menu-separator"></p>
                        <div id="nav-menu-social">
                            {
                                this.state.icons.map( icon => (
                                    <a key={icon.id} href={icon.link} target='_blank' rel="noopener noreferrer">
                                        <img alt="..." className="nav-menu-social-image" src={icon.image} />
                                    </a>
                                ))
                            }
                            <Link onClick={() => this.props.onJob()} offset={-convertRemToPixels(5)} smooth={true} to={"cont-container"}><img alt="..." className="nav-menu-social-image" src={job} /></Link>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Navbar;

