import React, {Component} from 'react';
import { Waypoint } from 'react-waypoint';
import '../css/desktop/ServicesSection.css'
import '../css/mobile/ServicesSection.css'
import '../css/tablet/ServicesSection.css'

import logo from '../../images/services/logo.png'
import bilancia from '../../images/services/bilancia.png'
import fotovoltaico from '../../images/services/fotovoltaico.png'
import luceGas from '../../images/services/luceGas.png'
import mobileInternet from '../../images/services/mobileInternet.png'
import sfondo1 from '../../images/services/sfondo1.png'
import sfondo2 from '../../images/services/sfondo2.png'

import ServiceInfo from './ServiceInfo'

function boudendTraslation(op, value){

    if(op && (value < 0))
        return 0;
    if(!op && (value > 50))
        return 50;
    return value;
}

function boundedScale(op, value){
    let float = parseFloat(value);
    if(op && float > 1)
        float = 1;
    if(!op && float < 0)
        float = 0;
    return (float + "");
}

class ServicesSection extends Component{
    state={
        automaticAnimation: true,
        animated: false,
        interval: "",
        animationSpeed: 40,
        animationDuration: 1000,
        rotation: 0,
        direction: true,
        services: [
            {
                id: 0, 
                scale: 0, 
                top: "50%", left: "50%", right: "auto", bottom: "auto", 
                image: luceGas, 
                name: "luceGas", 
                text: "",
                title: "",
                bigText: ""
            },
            {
                id: 1, 
                scale: 0, top: "50%", left: "auto", right: "50%", bottom: "auto", 
                image: fotovoltaico, 
                name: "fotovoltaico", 
                text: "",
                title: "",
                bigText: ""
            },
            {
                id: 2, 
                scale: 0, top: "auto", left: "50%", right: "auto", bottom: "50%", 
                image: mobileInternet, 
                name: "mobileInternet", 
                text: "",
                title: "",
                bigText: ""
            },
            {
                id: 3, 
                scale: 0, top: "auto", left: "auto", right: "50%", bottom: "50%", 
                image: bilancia, 
                name: "bilancia", 
                text: "",
                title: "",
                bigText: ""
            }
        ],
        serviceInfo:{
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto",
            interval: "",
            animationSpeed: 40,
            animationDuration: 200,
            display: "none",
            scale: 0,
            service: {},
        }

    }

    componentDidMount(){
        let items = this.state.services;
        let items_conf = window.SERVICE_SECTION;
        for(let i = 0; i < items_conf.length; i++){
           items[i].title = items_conf[i].title;
           items[i].text = items_conf[i].text;
           items[i].bigText = items_conf[i].bigText;
        }
        this.setState({services: items});
    }

    handleSevice(service){
        if(this.state.serviceInfo.display != "none")
            return;
        let serviceInfo = this.state.serviceInfo;
        serviceInfo.display = "flex";
        serviceInfo.service = service;

        if(serviceInfo.service.top == "auto")
            serviceInfo.bottom = "0%";
        else
            serviceInfo.top = "0%";
        if(serviceInfo.service.left == "auto")
            serviceInfo.right = "0%";
        else
            serviceInfo.left = "0%";

        this.setState({serviceInfo},
            () => {
                serviceInfo.interval = setInterval(
                    () => {
                        let translationStep = Math.floor(50 / (serviceInfo.animationDuration / serviceInfo.animationSpeed));
                        let scaleStep = parseFloat(1 / (serviceInfo.animationDuration / serviceInfo.animationSpeed));

                        if(serviceInfo.service.top == "auto")
                            serviceInfo.bottom = boudendTraslation(false, Number(serviceInfo.bottom.replace("%","")) + translationStep) + "%";
                        else
                            serviceInfo.top = boudendTraslation(false, Number(serviceInfo.top.replace("%","")) + translationStep) + "%";
                        if(serviceInfo.service.left == "auto")
                            serviceInfo.right = boudendTraslation(false, Number(serviceInfo.right.replace("%","")) + translationStep) + "%";
                        else
                            serviceInfo.left = boudendTraslation(false, Number(serviceInfo.left.replace("%","")) + translationStep) + "%";
            
                        serviceInfo.scale = boundedScale(true, parseFloat(serviceInfo.scale) + scaleStep);
                        
                        if(parseFloat(serviceInfo.scale) == 1){
                            if(serviceInfo.service.top == "auto")
                                serviceInfo.bottom = "50%";
                            else
                                serviceInfo.top = "50%";
                            if(serviceInfo.service.left == "auto")
                                serviceInfo.right = "50%";
                            else
                                serviceInfo.left = "50%";
                            clearInterval(serviceInfo.interval);
                        }
                        //console.log("top: " + serviceInfo.top + " left: " + serviceInfo.left + " scale: " + serviceInfo.scale);
                        this.setState({serviceInfo});
                    }
                , this.state.serviceInfo.animationSpeed)
                this.setState({serviceInfo})
            }
        );
    }

    handleCross = () => { 
        
        let serviceInfo = this.state.serviceInfo;
        serviceInfo.interval = setInterval(
            () => {
                let serviceInfo = this.state.serviceInfo;
                let translationStep = Math.floor(50 / (serviceInfo.animationDuration / serviceInfo.animationSpeed));
                let scaleStep = parseFloat(1 / (serviceInfo.animationDuration / serviceInfo.animationSpeed));
    
                if(serviceInfo.service.top == "auto")
                    serviceInfo.bottom = boudendTraslation(true, Number(serviceInfo.bottom.replace("%","")) - translationStep) + "%";
                else
                    serviceInfo.top = boudendTraslation(true, Number(serviceInfo.top.replace("%","")) - translationStep) + "%";
                if(serviceInfo.service.left == "auto")
                    serviceInfo.right = boudendTraslation(true, Number(serviceInfo.right.replace("%","")) - translationStep) + "%";
                else
                    serviceInfo.left = boudendTraslation(true, Number(serviceInfo.left.replace("%","")) - translationStep) + "%";
                
                serviceInfo.scale = boundedScale(false, parseFloat(serviceInfo.scale) - scaleStep);
                if(parseFloat(serviceInfo.scale) == 0){
                    serviceInfo.top = "auto";
                    serviceInfo.left = "auto";
                    serviceInfo.right = "auto";
                    serviceInfo.bottom = "auto";
                    clearInterval(serviceInfo.interval);
                    serviceInfo.display = "none";
                }
                this.setState({serviceInfo});
            }
        , this.state.serviceInfo.animationSpeed)
        this.setState({serviceInfo})
            
    }

    startAutomaticAnimation(){
        if(!this.state.automaticAnimation)
            return;
        this.setState({automaticAnimation: false}, () => { this.startAnimation()})
    }

    startAnimation(){
        if(this.state.animated)
            return;
        this.setState({animated: true},
            () => {
                let interval = setInterval(
                    () => {
                        let rotationStep = Math.floor(360 / (this.state.animationDuration / this.state.animationSpeed));
                        let translationStep = Math.floor(50 / (this.state.animationDuration / this.state.animationSpeed));
                        let scaleStep = parseFloat(1 / (this.state.animationDuration / this.state.animationSpeed));
                        let rotation = this.state.rotation;
                        let direction = this.state.direction;
                        let services = this.state.services;
                        
                        if(direction){
                            services[0].top = boudendTraslation(direction, Number(services[0].top.replace("%","")) - translationStep) + "%";
                            services[0].left = boudendTraslation(direction, Number(services[0].left.replace("%","")) - translationStep) + "%";
                            services[0].scale = boundedScale(direction, parseFloat(services[0].scale) + scaleStep);

                            services[1].top = boudendTraslation(direction, Number(services[0].top.replace("%","")) - translationStep) + "%";
                            services[1].right = boudendTraslation(direction, Number(services[0].left.replace("%","")) - translationStep) + "%";
                            services[1].scale = boundedScale(direction, parseFloat(services[1].scale) + scaleStep);

                            services[2].bottom = boudendTraslation(direction, Number(services[0].top.replace("%","")) - translationStep) + "%";
                            services[2].left = boudendTraslation(direction, Number(services[0].left.replace("%","")) - translationStep) + "%";
                            services[2].scale = boundedScale(direction, parseFloat(services[2].scale) + scaleStep);

                            services[3].bottom = boudendTraslation(direction, Number(services[0].top.replace("%","")) - translationStep) + "%";
                            services[3].right = boudendTraslation(direction, Number(services[0].left.replace("%","")) - translationStep) + "%";
                            services[3].scale = boundedScale(direction, parseFloat(services[3].scale) + scaleStep);

                            rotation += rotationStep;
                        }
                        else{
                            services[0].top = boudendTraslation(direction, Number(services[0].top.replace("%","")) + translationStep) + "%";
                            services[0].left = boudendTraslation(direction, Number(services[0].left.replace("%","")) + translationStep) + "%";
                            services[0].scale = boundedScale(direction, Number(services[0].scale) - scaleStep);
                            
                            services[1].top = boudendTraslation(direction, Number(services[0].top.replace("%","")) + translationStep) + "%";
                            services[1].right = boudendTraslation(direction, Number(services[0].left.replace("%","")) + translationStep) + "%";
                            services[1].scale = boundedScale(direction, Number(services[0].scale) - scaleStep);

                            services[2].bottom = boudendTraslation(direction, Number(services[0].top.replace("%","")) + translationStep) + "%";
                            services[2].left = boudendTraslation(direction, Number(services[0].left.replace("%","")) + translationStep) + "%";
                            services[2].scale = boundedScale(direction, Number(services[0].scale) - scaleStep);

                            services[3].bottom = boudendTraslation(direction, Number(services[0].top.replace("%","")) + translationStep) + "%";
                            services[3].right = boudendTraslation(direction, Number(services[0].left.replace("%","")) + translationStep) + "%";
                            services[3].scale = boundedScale(direction, Number(services[0].scale) - scaleStep);

                            rotation -= rotationStep;
                        }

                        if(rotation >= 360 || rotation <= -360){
                            rotation = 0;
                            clearInterval(this.state.interval);
                            direction = !direction;
                            this.setState({direction, animated: false, services});
                        }
                        this.setState({rotation});
                    }, this.state.animationSpeed);
                this.setState({interval});
            } 
        );
    }

    render(){
        return(
            <div id="ser-container">
                <Waypoint onEnter={() => this.startAutomaticAnimation()}/>
                <div id="ser-cont-0">
                    <label id="ser-title">I Nostri Servizi</label>
                    <p id="ser-separator"></p>
                    <div id="ser-cont-1">
                        <img alt="..." id="ser-image1" src={sfondo1}/>
                        <img alt="..." id="ser-image2" src={sfondo2}/>
                        <div id="ser-cont-2">
                            <ServiceInfo 
                                top = {this.state.serviceInfo.top}
                                left = {this.state.serviceInfo.left}
                                right = {this.state.serviceInfo.right}
                                bottom = {this.state.serviceInfo.bottom}
                                scale = {this.state.serviceInfo.scale}
                                display = {this.state.serviceInfo.display}
                                service = {this.state.serviceInfo.service}
                                handleCross = {this.handleCross}
                            />
                            {
                                this.state.services.map(service => (
                                    <div 
                                        key={service.id} 
                                        className="ser-cont-service"
                                        style={{
                                            top: service.top, 
                                            left: service.left, 
                                            right: service.right, 
                                            bottom: service.bottom, 
                                            scale: service.scale
                                        }}
                                        onClick = {() => this.handleSevice(service)}
                                    >
                                        <img alt="..." className="ser-service-image" src={service.image} />
                                        <p className="ser-service-text">{service.text}</p>
                                    </div>
                                ))
                            }
                            <div id="ser-cont-logo" onClick={() => this.startAnimation()}>
                                <img alt="..." src={logo}  style={{rotate: (this.state.rotation + "deg")}} />
                            </div>

                        </div>

                    </div>

                </div>
                
            </div>
        )
    }
}

export default ServicesSection;
