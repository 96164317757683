import React, {Component} from 'react';
import { PieChart, Pie} from 'recharts';
import { Waypoint } from 'react-waypoint';
import '../css/desktop/StatSection.css'
import '../css/mobile/StatSection.css'
import '../css/tablet/StatSection.css'

import image from '../../images/stat/sfondoStat.png';

function convertRemToPixels(rem) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

class StatSection extends Component{

    state={
        animated: false,
        interval: "",
        animationSpeed: 40,
        animationDuration: 1500,
        stats: [
            {id: 0, startValue: 0, value: 5000, text: "Già clienti in consulenza"},
            {id: 1, startValue: 0, value: 500, text: "Conciliazioni vinte"},
            {id: 2, startValue: 0, value: 300000, text: "Euro risparmiati dai clienti"},
            {id: 3, startValue: 0, value: 95, text: "Clienti soddisfatti"}
        ],
        piechart: {
            value: 0,
            firstColor: "#cbfd92",
            secondColor: "transparent",
            thirdColor: "#77db98"
        }
    }

    componentDidMount(){
        let items = [];
        let items_conf = window.STATS_CONFIG;
        for(let i = 0; i < items_conf.length; i++){
            let id = i;
            let value = items_conf[i].value;
            let text = items_conf[i].text;
            let item = {id: id, startValue: 0, value: value, text: text};
            items.push(item);
        }
        this.setState({stats: items});
    }

    startAnimation(){
        if(this.state.animated)
            return;
        let interval = setInterval(
            () => {
                let stats = this.state.stats;
                let piechart = this.state.piechart;
                for(let i = 0; i < stats.length; i++){
                    let step = Math.floor(stats[i].value / (this.state.animationDuration / this.state.animationSpeed));
                    stats[i].startValue = stats[i].startValue + step;
                }
                piechart.value = piechart.value +  Math.floor((360 / (this.state.animationDuration / this.state.animationSpeed)));
                if(stats[0].startValue >= stats[0].value){
                    stats[0].startValue = stats[0].value;
                    stats[1].startValue = stats[1].value;
                    stats[2].startValue = stats[2].value;
                    stats[3].startValue = stats[3].value;
                    piechart.value = 360;
                    piechart.secondColor = piechart.thirdColor;
                    clearInterval(this.state.interval)
                }
                
                this.setState({stats, piechart});
            }, this.state.animationSpeed);
        this.setState({interval, animated: true});
    }

    render(){
        return(
            <div id="stat-container">
                <div id="stat-cont-0">
                    <Waypoint onEnter={() => {this.startAnimation()}}/>
                    <div id="stat-cont-1">
                        <div className="stat-cont-2">
                            <label className="stat-value">{this.state.stats[0].startValue}</label>
                            <label className="stat-text">{this.state.stats[0].text}</label>
                        </div>
                        <div className="stat-cont-2">
                            <label className="stat-value">{this.state.stats[1].startValue}</label>
                            <label className="stat-text">{this.state.stats[1].text}</label>
                        </div>
                        <div className="stat-cont-2">
                            <label className="stat-value">{this.state.stats[2].startValue}</label>
                            <label className="stat-text">{this.state.stats[2].text}</label>
                        </div>
                    </div>
                    <div id="stat-cont-3">
                        <div id="stat-cont-3-1">
                            <div id="stat-pie-container">
                                <PieChart 
                                    id="stat-pie" 
                                    width={convertRemToPixels(document.documentElement.clientWidth > 1024 ? 15 : 10)} 
                                    height={convertRemToPixels(document.documentElement.clientWidth > 1024 ? 15 : 10)}
                                    >
                                    <Pie 

                                        data={
                                            [
                                                {name: "Clienti soddisfatti", value: this.state.stats[3].value, fill: this.state.piechart.firstColor },
                                                {name: "Clienti non soddisfatti", value: (100 - this.state.stats[3].value), fill: this.state.piechart.secondColor}
                                            ]
                                        } 
                                        isAnimationActive={false}
                                        startAngle={0}
                                        endAngle={this.state.piechart.value}
                                        cx="50%"
                                        Cy="50%"
                                        dataKey="value" 
                                    />
                                </PieChart>
                            </div>
                            <div className="stat-cont-2">
                                <label className="stat-value">{this.state.stats[3].startValue}%</label>
                                <label className="stat-text">{this.state.stats[3].text}</label>
                            </div>
                        </div>
                    </div>
                    <img alt="..." id="stat-image" src={image}/>
                </div>
            </div>
        )
    }
}

export default StatSection;

